import React from 'react'
import { Section, Container } from './layout.jsx'
import cx from 'classnames'

import Empty from './empty'
import './__styles__/documents.module.scss'

const Documents = ({ data }) => (
  <Section>
    <Container>
      <h3 styleName="title">Documents</h3>
    </Container>
    {data.map(doc => (
      <div key={doc.id} styleName="document-row">
        <Container>
          <a
            href={doc.file.file.url}
            target="_blank"
            styleName={cx('document-link', {
              pdf: doc.type === 'PDF',
              docs: doc.type === 'Docs',
              xlsx: doc.type === 'XLSX',
            })}
            rel="noopener noreferrer"
          >
            {doc.name}{' '}
            {doc.isForerunnerTemplate && (
              <span styleName="by-forerunner-tag">by Forerunner</span>
            )}
          </a>
        </Container>
      </div>
    ))}
    {!data.length && <Empty type="Documents" />}
  </Section>
)

export default Documents
